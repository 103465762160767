/*
Author: Kaushik 
Purpose: This is common style which will be applied to editor and all over pages for the editor related content styles. 

*/

/* Link style */
.tbnc-content .tbnc-default-link {
  font-size: 18px !important;
  color: #000000 !important;
  text-decoration: none !important;
  border-bottom: 2px solid #00a8a8;
}

/* bTN  style */
.tbnc-content .tbnc-btn-link {
  font-size: 18px !important;
  color: #000000 !important;
  text-decoration: none !important;
  border-bottom: 2px solid red !important;
  display: block !important;
	width: max-content !important;
  margin: 5px auto !important;
	padding: 6px 18px !important;
}

/* This is for default block styles in the editor or all the places.  */
.tbnc-content .tbnc-default-block {
  margin-top: 1rem !important;
  margin-top: 1rem !important;
  font-size: 18px !important;
  line-height: 1.4 !important;
}

/* Default UL.  */
.tbnc-content .tbnc-default-ul {
  margin-top: 1rem !important;
  margin-top: 1rem !important;
  font-size: 18px !important;
  line-height: 1.4 !important;
}

.tbnc-content .public-DraftStyleDefault-ul {
}

.tbnc-content .tbnc-teal-bold {
  color: #00a8a8;
  font-weight: bold;
  font-size: 18px !important;
}

.tbnc-content .tbnc-inline-bold {
  font-size: 18px;
  font-weight: bold;
}

.tbnc-content .tbnc-inline-italic {
  font-size: 18px;
  font-style: italic;
}

.tbnc-content .tbnc-inline-underline {
  font-size: 18px;
  text-decoration: underline;
}
