body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.full-width {
  width: 100%;
}

.spinner-5 {
  width: 5rem;
  height: 5rem;
}

.min-h-100 {
  min-height: 100px;
  max-height: 150px;
}

.page-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  cursor: pointer;
}

.page-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.mask-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 120px;
  width: 120px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 10px solid rgba(0, 174, 239, 0.15);
  border-right: 10px solid rgba(0, 174, 239, 0.15);
  border-bottom: 10px solid rgba(0, 174, 239, 0.15);
  border-top: 10px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Georgia", serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

/* ******************************************************************** */
/* ROUNDUP CSS START */
/* ******************************************************************** */

.roundup-id {
  width: 3%;
}

.roundup-publish-date {
  width: 12%;
}

.rounup-category {
  width: 10%;
}

.roundup-opening-text {
  width: 60%;
}

.roundup-actions {
  width: 15%;
}

/* ******************************************************************** */
/* ROUNDUP CSS END */
/* ******************************************************************** */

/* ******************************************************************** */
/* STORY CSS START */
/* ******************************************************************** */

.story-id {
  width: 3%;
}

.story-headline {
  width: 55%;
}

.story-category {
  width: 12%;
}

/* .story-image {
  width: 6%;
} */

.story-actions {
  width: 15%;
}

.story-modified {
  width: 15%;
}

/* ******************************************************************** */
/* STORY CSS END */
/* ******************************************************************** */

.contactpagelist li {
  padding: 4px 11px;
  border: 2px solid #b3b3b3;
  background-color: #bbc1c7;
  border-radius: 5px;
  margin: 2px 4px;
}

.contactpagelist li.active {
  background-color: #0c5eb1;
}

.contactpagelist li a {
  outline: none;
}

.rounded-corner {
  border-radius: 5px;
}

.GODEEPER {
  border-bottom: 4px solid #00a8a8;
  width: 100px;
  margin-bottom: 25px;
  margin-top: 25px;

  /* wid
  width: 100%,
  height: "5px",
  marginBottom: "25px",
  marginTop: "25px",
  display: "inline-block", */
}

.bg-progress {
  background-color: #e3f1f0;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.nl-alert-bg {
  background-color: #fff5f5;
}

.nl-partnership-bg {
  background-color: #f3fff3;
}

.nl-custom-box {
  padding: 10px 20px 3px 20px;
  border-radius: 5px;
}

.draggable {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.draggable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.droppable:hover {
  background-color: #d4d4d4;
}

/* ******************************************************************** */
/* FINACE TABLE CSS START  */
/* ******************************************************************** */

/* ******************************************************************** */
/* FINACE TABLE CSS END */
/* ******************************************************************** */

.editor-button-contols {
  border: 1px solid #d4d4d4;
  padding: 3px 10px;
  border-radius: 4px;
  margin-right: 5px;
  display: inline-block;
  cursor: pointer;
  color: #888;
}

.editor-link-control {
  margin: 4px 1px;
  border: 1px solid #d4d4d4;
  background-color: #133af9;
  color: #fff;
  padding: 3px 10px;
  border-radius: 5px;
}

.editor-link-input {
  padding: 4px 2px;
  margin: 10px 2px;
  width: 60%;
  border: 1px solid #cecece;
  border-radius: 5px;
}

.editor-link-input:focus {
  border: 1px solid #cecece;
  border-radius: 5px;
  outline: none !important;
}

.editor-font {
  font-size: 18px;
}

.editor-active-contols {
  color: #fff;
  background-color: #4a8cde;
}

.editor-adjust {
  margin-top: 1em;
  max-height: 500px;
  min-height: 500px;
  overflow: auto;
  font-size: 18px;
}

.editor-view {
  border: 1px solid #d4d4d4;
  padding: 10px;
  margin: 10px 0px;
  min-height: 100px;
  overflow: auto;
  max-height: 200px;
  border-radius: 5px;
  box-shadow: 1px 2px 5px #d4d4d4;
  box-shadow: 1px 1px 10px #d4d4d4;
}

.md-checkbox
{
  -ms-transform: scale(1.3); /* IE */
  -moz-transform: scale(1.3); /* FF */
  -webkit-transform: scale(1.3); /* Safari and Chrome */
  -o-transform: scale(1.3); /* Opera */
  transform: scale(1.3);
  padding: 10px;
}